// Libs
import React, { createContext, useState, useEffect } from 'react';

const defaultState = {
  hasFooter: true,
  setHasFooter: () => {},
  setContactUs: () => {},
};

export const FooterContext = createContext(defaultState);

export default function FooterProvider({ children }) {
  const [hasFooter, setHasFooter] = useState(true);
  const [contactUs, setContactUs] = useState('');

  const handleHasFooter = () => {
    return setHasFooter(!hasFooter);
  };

  return (
    <FooterContext.Provider
      value={{
        hasFooter,
        setHasFooter: handleHasFooter,
        contactUs,
        setContactUs: screen => setContactUs(screen),
      }}
    >
      {children}
    </FooterContext.Provider>
  );
}
