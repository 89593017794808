// Libs
import React, { createContext, useState, useEffect } from 'react';
import styled from 'styled-components';

// Components
import { device } from 'components/device';

const defaultState = {
  hasDefaultHeader: true,
  setHasDefaultHeader: () => {},
  headerText: 'Fale Conosco',
  setHeaderText: () => {},
  callCenterBackLink: '',
  setCallCenterBackLink: () => {},
  hideHeader: false,
  setHideHeader: () => {},
};

const Container = styled.div`
  header {
    @media ${device.laptop} {
      position: fixed;
      top: ${props =>
        props.showAlertPostFeriado || props.isVisibleNovoSite ? '2rem' : 0};
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom: 0.063rem solid #c6c8cc;
    }
  }

  main {
    @media ${device.laptop} {
      margin-top: ${props =>
        props.mainMarginTopLaptop ? props.mainMarginTopLaptop : '9.525rem'};
    }

    @media ${device.mobile} {
      margin-top: ${props =>
        props.mainMarginTopMobile ? props.mainMarginTopMobile : '9.525rem'};
    }
  }
`;

export const HeaderContext = createContext(defaultState);

export default function HeaderProvider({ children, text, link }) {
  const [hasDefaultHeader, setHasDefaultHeader] = useState(true);
  const [showAlertPostFeriado, setShowAlertPostFeriado] = useState(false);
  const [linkPostFeriado, setLinkPostFeriado] = useState('');
  const [isVisibleNovoSite, setIsVisibleNovoSite] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [headerText, setHeaderText] = useState('Fale Conosco');
  const [scroll, setScroll] = useState(false);
  const [callCenterBackLink, setCallCenterBackLink] = useState('');
  const [isOverflow, setIsOverflow] = useState(false);
  const [fixedSelect, setFixedSelect] = useState(false);
  const [mainMarginTopLaptop, setMainMarginTopLaptop] = useState(null);
  const [mainMarginTopMobile, setMainMarginTopMobile] = useState(null);

  const handleHasFooter = () => {
    return setHasDefaultHeader(!hasDefaultHeader);
  };
  useEffect(() => {
    if (
      window.innerWidth <= 1024 &&
      (callCenterBackLink === '/fale-conosco/' ||
        callCenterBackLink === 'fale-conosco')
    ) {
      setHasDefaultHeader(true);
    }
  });

  return (
    <Container
      showAlertPostFeriado={showAlertPostFeriado}
      isVisibleNovoSite={isVisibleNovoSite}
      mainMarginTopLaptop={mainMarginTopLaptop}
      mainMarginTopMobile={mainMarginTopMobile}
    >
      <HeaderContext.Provider
        value={{
          hasDefaultHeader,
          setHasDefaultHeader: handleHasFooter,
          headerText,
          setHeaderText: text => setHeaderText(text),
          callCenterBackLink,
          setCallCenterBackLink: link => setCallCenterBackLink(link),
          isOverflow,
          setIsOverflow: value => setIsOverflow(value),
          scroll,
          setScroll: value => setScroll(value),
          fixedSelect,
          setFixedSelect: value => setFixedSelect(value),
          hideHeader,
          setHideHeader: value => setHideHeader(value),
          showAlertPostFeriado,
          setShowAlertPostFeriado,
          linkPostFeriado,
          setLinkPostFeriado,
          isVisibleNovoSite,
          setIsVisibleNovoSite,
          mainMarginTopLaptop,
          setMainMarginTopLaptop,
          mainMarginTopMobile,
          setMainMarginTopMobile,
        }}
      >
        {children}
      </HeaderContext.Provider>
    </Container>
  );
}
