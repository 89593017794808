// Libs
import React from 'react';

// Providers
import FooterProvider from './src/context/footer';
import HeaderProvider from './src/context/header';
import DoctorProvider from './src/context/doctor';
import PaginationProvider from './src/context/pagination';
import TabsProvider from './src/context/tabs';
import InternacionalizationProvider from './src/context/internacionalization';
import { ThemeProvider } from '@tempo/tempo-design-system-core/dist/theme';

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Incluir ferramenta "Optmize"
  const script = document.createElement('script');
  script.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-NM3G63J';
  document.head.appendChild(script);
  typeof window !== 'undefined' && window.scrollTo(0, 0);
};

export const wrapRootElement = ({ element }) => (
  <InternacionalizationProvider>
    {typeof window !== 'undefined' && { ThemeProvider } && (
      <ThemeProvider brand="hsl" theme="default">
        <HeaderProvider>
          <FooterProvider>
            <DoctorProvider>
              <PaginationProvider>
                <TabsProvider>{element}</TabsProvider>
              </PaginationProvider>
            </DoctorProvider>
          </FooterProvider>
        </HeaderProvider>
      </ThemeProvider>
    )}
  </InternacionalizationProvider>
);
