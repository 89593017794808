// Libs
import React, { createContext, useState } from 'react';

export const DoctorContext = createContext();

export default function DoctorProvider({ children }) {
  const [specialty, setSpecialty] = useState('');
  const [practiceArea, setPracticeArea] = useState('');
  const [name, setName] = useState('');
  const [crm, setCrm] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [result, setResult] = useState([]);
  const [isNameFiltered, setIsNameFiltered] = useState([]);
  const [isCrmFiltered, setIsCrmFiltered] = useState([]);
  const [filters, setFilters] = useState([]);
  const [renderList, setRenderList] = useState('all');
  const [doctorsList, setDoctorsList] = useState(undefined);
  const [specialtyList, setSpecialtyList] = useState([]);
  const [specialtyId, setSpecialtyId] = useState('');
  const [doctorsOncology, setDoctorsOncology] = useState([]);
  const [scroll, setScroll] = useState(false);

  return (
    <DoctorContext.Provider
      value={{
        name,
        crm,
        specialty,
        result,
        isNameFiltered,
        filters,
        isCrmFiltered,
        renderList,
        doctorsList,
        specialtyList,
        specialtyId,
        doctorsOncology,
        scroll,
        nameSearch,
        practiceArea,
        setPracticeArea: value => setPracticeArea(value),
        setScroll: value => setScroll(value),
        setDoctorsOncology: value => setDoctorsOncology(value),
        setName: value => setName(value),
        setNameSearch: value => setNameSearch(value),
        setSpecialty: value => setSpecialty(value),
        setCrm: value => setCrm(value),
        setIsCrmFiltered: value => setIsCrmFiltered(value),
        setIsNameFiltered: value => setIsNameFiltered(value),
        setFilters: value => setFilters(value),
        setResult: value => setResult(value),
        setRenderList: value => setRenderList(value),
        setDoctorsList: value => setDoctorsList(value),
        setSpecialtyList: value => setSpecialtyList(value),
        setSpecialtyId: value => setSpecialtyId(value),
      }}
    >
      {children}
    </DoctorContext.Provider>
  );
}
