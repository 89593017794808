// Libs
import React, { createContext, useState } from 'react';

export const PaginationContext = createContext();

export default function PaginationProvider({ children }) {
  const [pagination, setPagination] = useState([]);

  return (
    <PaginationContext.Provider
      value={{ pagination, setPagination: page => setPagination(page) }}
    >
      {children}
    </PaginationContext.Provider>
  );
}
