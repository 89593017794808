// Libs
import React, { createContext, useState } from 'react';
export const TabsContext = createContext();
export default function TabsProvider({ children }) {
  const [tabs, setTabs] = useState('');
  const [activeTabs, setActiveTabs] = useState('');
  return (
    <TabsContext.Provider
      value={{
        tabs,
        activeTabs,
        setTabs: value => setTabs(value),
        setActiveTabs: value => setActiveTabs(value),
      }}
    >
      {children}
    </TabsContext.Provider>
  );
}
