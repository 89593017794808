// Libs
import React, { createContext, useState } from 'react';

export const InternacionalizationContext = createContext();

export default function InternacionalizationProvider({ children }) {
  const [language, setLanguage] = useState('PT');
  const [showSelect, setShowSelect] = useState(false);

  return (
    <InternacionalizationContext.Provider
      value={{
        language,
        setLanguage: value => setLanguage(value),
        showSelect,
        setShowSelect: value => setShowSelect(value),
      }}
    >
      {children}
    </InternacionalizationContext.Provider>
  );
}
